<template lang="pug">
.project-page.flex.column
  page-block-title(
    title="工程實績"
    imageSrc="/images/title/project.png")
  .bg-image
  .project-container.flex.column.align-center.pt-64.pb-90.gap-20
    .filter.flex.column.gap-24(ref="filter")
      .title.flex.align-center
        .text 產業分類
        .line
      .types.flex.align-center.gap-16.wrap
        .type.p-8.clickable.has-animate(
          @click="chooseType(t.key)"
          :class="{'selected': selectedType === t.key, 'short': t.short}"
          v-for="t in types", :key="t.key") {{ t.text }}
    .projects.flex.column.mt-62.gap-20
      project-card(v-for="(p, idx) in projects", :key="idx", :data="p", ref="card")
    .pages.flex.align-center.gap-8
      template(v-for="(idx, i) in showedPageLink")
        .page.flex.align-center.justify-center.clickable(
          v-if="idx !== ''"
          @click="goPage(idx)"
          :key="i", :class="{'active': idx === page}") {{ idx }}
        .flex.align-center.justify-center(v-else, :key="i") ...
  page-footer
</template>

<script>
import { mapMutations } from 'vuex';
import ProjectCard from '@/components/projects/ProjectCard.vue';
import Paginator from '@/components/Paginator.vue';
import { GetCompanyProjects } from '@/api/company';

export default {
  components: {
    'project-card': ProjectCard,
    paginator: Paginator,
  },
  data() {
    return {
      types: [
        { text: '全部', key: '' },
        { text: '科技廠', key: '科技廠' },
        { text: '生技/食品廠', key: '生技廠' },
        { text: '太陽能廠', key: '太陽能廠' },
        { text: '學術機關', key: '學術機關' },
        { text: '飯店商辦', key: '飯店商辦' },
        { text: '無塵衣物專業清洗廠', key: '無塵衣物專業清洗廠', short: true },
      ],
      projects: [],
      selectedType: '',
      page: 1,
      total: 0,
      pageCount: 1,
      limit: 10,
    };
  },
  computed: {
    showedPageLink() {
      const p = this.page;
      const order = [p - 1, p, p + 1];
      if (p - 1 < 1) {
        order.shift();
      }
      if (p + 1 > this.pageCount) {
        order.pop();
      }

      if (p - 1 >= 2) {
        order.unshift(1, '');
      }
      if (p + 1 <= this.pageCount - 1) {
        order.push('', this.pageCount);
      }
      return order;
    },
  },
  methods: {
    ...mapMutations(['setAnimateFinish']),
    chooseType(t) {
      this.selectedType = t;
      this.page = 1;
      this.projects = [];
      setTimeout(() => {
        this.loadData();
      }, 10);
    },
    async loadData(shift) {
      if (shift && shift !== 0) {
        this.page = parseInt((shift || 0) / this.limit, 10) + 1;
      }
      const rsp = await GetCompanyProjects(this.selectedType, this.page, this.limit);
      const projects = rsp.data?.projects || [];
      this.total = rsp.data?.total || 0;
      let pageCount = parseInt((this.total - 1) / this.limit, 10) + 1;
      if (pageCount <= 0) {
        pageCount = 1;
      }
      this.pageCount = pageCount;
      this.projects = [];
      await this.$waitMs(10);
      this.projects = projects.map((p) => ({
        id: p.no,
        name: p.customer_name,
        place: p.location,
        items: p.items,
        industry: p.industry,
        image: p.image === '' ? '' : `/api/image/${p.image}`,
        custom: Object.keys(p.custom || {}).map((k) => ({
          title: k,
          value: p.custom[k],
        })),
      }));
      await this.$waitMs(10);
      console.log(this.$refs.card);
      if (shift && shift !== 0) {
        const shiftIdx = shift % this.limit;
        console.log(shiftIdx);
        document.querySelector('#app').scrollTop = 500
          + this.$refs.filter.clientHeight
          + 120 * shiftIdx;
        if (this.$refs.card[shiftIdx]) {
          this.$refs.card[shiftIdx].$emit('expand');
        }
      }
    },
    async goPage(page) {
      this.page = page;
      document.querySelector('#app').scrollTop = 500 + this.$refs.filter.clientHeight;
      await this.$waitMs(10);
      this.loadData();
    },
  },
  mounted() {
    this.$setTitle('頂鑫科技工程有限公司/宥陞工程有限公司 - 工程實績');
    const param = new URLSearchParams(document.location.search);
    const shift = param.get('shift');
    this.loadData(shift || 0);
    this.setAnimateFinish(true);
  },
};
</script>

<style lang="scss" scoped>
.project-page {
  position: relative;
  .bg-image {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100vw;
    margin-bottom: -100vh;
    background-image: url('/bg.png');
    background-size: cover;
  }
}
.project-page {
  position: relative;
  .project-container {
    position: relative;
    .filter {
      width: calc(100vw - 40px);
      max-width: 970px;
      .types {
        .type {
          white-space: nowrap;
          flex: 0 0 180px;
          border-radius: 80px;
          border: 1px solid var(--lightgreen-40, #E5ECB9);
          &:hover {
            border: 1px solid var(--lightgreen, #BACF39);
            background: var(--lightgreen-20, #F3F6DE);
          }
          &.short {
            flex: 0 0 160px;
          }
          &.selected {
            border: 1px solid var(--lightgreen, #BACF39);
            background: var(--lightgreen, #BACF39);
            color: white;
          }
        }
      }
    }
    .pages {
      .page {
        width: 68px;
        height: 68px;
        border-radius: 34px;
        gap: 9px;
        border: 1px solid #DEDDDD;
        &.active {
          background: #BACF39;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .project-page {
    .project-container {
      .filter {
        .types {
          justify-content: center;
          .type {
            flex: 1 0 40%;
            max-width: 160px;
          }
        }
      }
    }
  }
}
</style>
