<template lang="pug">
.paginator.flex.align-center
  button.prev(
    alt="上一頁",
    title="上一頁",
    @click="prevPage",
    :disabled="curPage === 1"
  ) <
  .current.flex.align-center(
    ref="cur",
    :alt="`總共${totalPage}頁，目前在第${curPage}頁`"
  )
    div {{ curPage }}
    div /
    div {{ totalPage }}
  button.next(
    alt="下一頁",
    title="下一頁",
    @click="nextPage",
    :disabled="curPage === totalPage"
  ) >
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    records: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      curPage: 1,
      totalPage: 1,
    };
  },
  watch: {
    records() {
      this.calculate();
    },
    perPage() {
      this.calculate();
    },
  },
  methods: {
    changePage() {
      this.$emit('paginate', this.curPage);
    },
    prevPage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
      }
      if (this.curPage === 1) {
        this.$refs.cur.focus();
      }
      this.changePage();
    },
    nextPage() {
      if (this.curPage < this.totalPage) {
        this.curPage += 1;
      }
      if (this.curPage === this.totalPage) {
        this.$refs.cur.focus();
      }
      this.changePage();
    },
    calculate() {
      this.curPage = this.value;
      this.totalPage = Math.floor((this.records - 1) / this.perPage) + 1;
    },
  },
  mounted() {
    this.calculate();
  },
};
</script>

  <style lang="scss" scoped>
  .clickable {
    cursor: pointer;
    user-select: none;
  }
  .paginator {
    gap: 6px;
  }
  </style>
